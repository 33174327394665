$('body').on('click', '.enlarge img', function () {

    const src = $(this).attr('src');

    let html = '<div class="shadow"></div>';

    html += '<div class="modal">';

        html += '<img src="' + src + '" />';

        html += '<a class="close">X</a>';

    html += '</div>';

    $('body').append(html);

});

$('body').on('click', '.modal .close', function () {
    const $_this = $(this);
    $_this.closest('.modal').prev('.shadow').remove();
    $_this.closest('.modal').remove();
});

$('body').on('click', '.shadow', function () {
    const $_this = $(this);
    $_this.next('.modal').remove();
    $_this.remove();
});

$('body').on('click', '.info-buttons .button', function () {

    const content = $(this).next('.content');

    if (content.length > 0) {
        getModal('<div class="content-info">' + content.html() + '</div>');
    }

});

$('body').on('click', '.tabs-part-name', function () {
    getModal('<div class="content-info">' + $(this).html() + '</div>');
});

$('body').on('click', '.shadow', function () {
    $('.modal-window').remove();
    $(this).remove();
});


function getModal(html) {

    let modal = '<div class="shadow"></div>';
    modal += '<div class="modal-window">';
        modal += html;
    modal += '</div>';

    $('body').append(modal);

}

