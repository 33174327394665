;(function($){
    const loginForm = $('#js-login-form'),
        regForm = $('#js-reg-popup'),
        contactForm =$('#js-contact-form'),
        forgotForm = $('#js-forgot-popup'),
        resetForm = $('#js-reset-popup'),
        datepickerLoc = {
            en: {
                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                months: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
                monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                today: 'Today',
                clear: 'Clear'
            },
        }


    if ($('.referred_time').length) {
        var dateTimeNow = new Date();
        dateTimeNow.setHours(dateTimeNow.getHours() + 1);
        $('.referred_time').datepicker({
            timepicker: true,
            minDate: dateTimeNow,
            language: datepickerLoc['en'],
            onSelect: function(formattedDate, date, inst) {
                var el = inst.el;
                if($(el).parent().find('p')){
                    $(el).parent().find('p').remove();
                }
            }
        });
    }

    function FormValidateCus(event) {
        event.preventDefault();

        var form = null;
        var fields = null;
        var errors = [];
        var lang = $('html').attr('lang');
        var requiredError = translateRequiredError();
        var incorrectError = translateIncorrectError();

        function translateRequiredError() {
            if (lang === "ar") {
                return 'يجب ملء هذا الحقل';
            }
            return 'This field must be filled';
        }

        function translateIncorrectError() {
            if (lang === "ar") {
                return 'هذا الحقل غير ممتلئ بشكل صحيح';
            }
            return 'This field is not filled correctly';
        }
        if( event.type === "submit" ) {
            form = event.target;
            fields = $(form)
                .find(':input')
                .not('button')
                .not('select')
                .not('input[type="file"]')
                .not('input[type="checkbox"]:not(.validation)')
                .not('input[type="hidden"]');
        }
        if( event.type === "blur" ) {
            form = event.target;
        }
        if( event.type === "change" ) {
            clearMessage(event.target);
        }

        /* Functions with RegExp for fields */
        function checkText(val) {
            var regExp = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9_ ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9-_\.]*$/gm;
            return regExp.test(val);
        }
        function checkBoolean(val) {
            return val;
        }
        function checkPassword(val) {
            var regExp = /^[A-Za-z0-9_$&+,:;=?@#|'<>.^*()%!\- ]*$/gm;
            return regExp.test(val);
        }
        function checkNumber(val) {
            var regExp = /^[0-9]*$/;
            return regExp.test(val);
        }
        function checkURL(val) {
            var regExp = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return regExp.test(val);
        }
        function checkEmail(email) {
            var re = /[\u0621-\u064A\u0660-\u0669a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[\u0621-\u064A\u0660-\u0669a-zA-Z0-9][\u0621-\u064A\u0660-\u0669a-zA-Z0-9\-]{0,64}(\.[\u0621-\u064A\u0660-\u0669a-zA-Z0-9][\u0621-\u064A\u0660-\u0669a-zA-Z0-9\-]{0,25})$/;
            return re.test(String(email).toLowerCase());
        }
        function checkDateField(val) {
            return val.length > 0;
        }

        /* Choose input type */
        function validateItem(rule, item) {
            var result = null;

            switch(rule) {
                case 'text': result = checkText(item);
                    break;
                case 'boolean': result = checkBoolean(item);
                    break;
                case 'number': result = checkNumber(item);
                    break;
                case 'url': result = checkURL(item);
                    break;
                case 'email': result = checkEmail(item);
                    break;
                case 'message': result = checkText(item);
                    break;
                case 'date': result = checkDateField(item);
                    break;
                case 'password': result = checkPassword(item);
                    break;

                default: result = false;
            }

            return result;
        }

        /* Error message */
        function generateMessage(item, isEmpty) {
            var parent = $(item).parent();
            if(parent.find('p')) {
                $(item).removeClass('f-error');
                clearMessage(item);
            }
            $(item).addClass('f-error');
            var p = document.createElement('P');
            p.classList.add('f-alert', 'f-alert--error');
            if(isEmpty) {
                p.innerText = requiredError;
            } else {
                p.innerText = incorrectError;
            }
            parent.append(p);
        }

        /* Clear error message */
        function clearMessage(item) {
            $(item).removeClass('f-error');
            if($(item).next('p').length > 0) {
                $(item).next('p').remove();
            } else if ($(item).next('label').next('p').length > 0) {
                $(item).next('label').next('p').remove();
            }
        }

        /* check current input */
        function checkInput(el) {
            var _target = el;
            var _type = _target.dataset.inpType;

            if(_target.value.length > 2) {
                if (_type === 'boolean') {
                    var validateResult = validateItem(_type, _target.checked);
                } else {
                    var validateResult = validateItem(_type, _target.value);
                }
                if(validateResult) {
                    clearMessage(_target);
                } else {
                    generateMessage(_target, false);
                    errors.push(true);
                }
            } else {
                generateMessage(_target, true);
                errors.push(true);
            }
        }

        /* Check all form elements */
        if( event.type === "submit" ) {
            fields.each(function(indx, el) {
                checkInput(el);
            });
        } else {
            checkInput(form);
        }

        function checkErr(curr) {
            return curr;
        }

        if(!errors.some(checkErr) && event.type === "submit" ) {
            form.submit();
        };

        return this;
    }

    $('.js-validation').on('submit', function(event) {
        event.preventDefault();
        let currentForm = new FormValidateCus(event);
    });
    $('.js-validation')
        .find(':input')
        .not('button')
        .not('select')
        .not('input[type="file"]')
        .not('input[type="checkbox"]:not(.validation)')
        .not('input[type="hidden"]')
        .on('blur', function(event) {
            let currentForm = new FormValidateCus(event);
        });


})(jQuery);
