;(function($){
    const loginBtn = document.getElementById('js-login'),
        loginPopup = document.getElementById('js-login-popup'),
        regBtn = document.getElementById('js-registration'),
        regPopup = document.getElementById('js-reg-popup'),
        verifyPopup = document.getElementById('js-verify-popup'),
        isShouldVerifyEmail = document.getElementById('userShouldVerifyEmail'),
        verifyPopupCloseBtn = document.getElementById('verifyPopupCloseBtn'),
        jsLogout = document.getElementById('js-logout'),
        shouldLogin = document.getElementById('shouldLogin'),
        forgotBtns = document.getElementsByClassName('forgotBtn'),
        forgotPopup = document.getElementById('js-forgot-popup'),
        resetPopup = document.getElementById('js-reset-popup'),
        shouldReset = document.getElementById('shouldReset'),
        checkEmailResetCloseBtn = document.getElementById('checkEmailResetCloseBtn'),
        CheckEmailResetPopup = document.getElementById('js-check-email-reset-popup'),
        shouldCheckEmail = document.getElementById('shouldCheckEmail'),
        contactUsBtns = document.getElementsByClassName('contactUsBtn'),
        contactPopup = document.getElementById('js-contact-popup'),
        fileInput = document.getElementById('file-input'),
        messageSentPopup = document.getElementById('js-message-sent-popup'),
        isMessageSent = document.getElementById('is-message-sent'),
        bookingErrorPopup = document.getElementById('booking-error-popup'),
        messagePopupCloseBtn = document.getElementById('messagePopupCloseBtn'),
        bookingPopupCloseBtn = document.getElementById('bookingPopupCloseBtn');


    let closeBtn = $('.popup-btn-cl');

    if (loginBtn) {
        loginBtn.addEventListener('click', function(event) {
            event.preventDefault();

            $(loginPopup).fadeIn();
        });
    }

    if (regBtn) {
        regBtn.addEventListener('click', function(event) {
            event.preventDefault();

            $(regPopup).fadeIn();
        });
    }

    for (var i = 0; i < contactUsBtns.length; i++) {
        contactUsBtns[i].addEventListener('click', function(event) {
            event.preventDefault();

            $(contactPopup).fadeIn();
        });
    }

    for (var i = 0; i < forgotBtns.length; i++) {
        forgotBtns[i].addEventListener('click', function(event) {
            event.preventDefault();

            $(forgotPopup).fadeIn(500, function() {
                $(loginPopup).fadeOut(50);
            });
        });
    }

    closeBtn.on('click', function() {
        const _this = $(this),
            wrapper = _this.parents('.popup-wr'),
            form = wrapper.find('form');

        wrapper.fadeOut(500, function() {
            $(this).removeAttr('style');
            form[0].reset();
            form.find('[type="submit"]').attr('disabled', true)
        });
    });

    if (isShouldVerifyEmail) {
        $(verifyPopup).fadeIn();
    }

    if(shouldLogin) {
        $(loginPopup).fadeIn();
    }

    if(shouldReset) {
        $(resetPopup).fadeIn();
    }

    if(shouldCheckEmail) {
        $(CheckEmailResetPopup).fadeIn();
    }

    if (isMessageSent) {
        $(messageSentPopup).fadeIn();
    }

    if($('.reg-error').length) {
        $(regPopup).fadeIn();
    }

    if($('.login-error').length) {
        $(loginPopup).fadeIn();
    }

    if($('.reset-error').length) {
        $(resetPopup).fadeIn();
    }

    if($('.contact-error').length) {
        $(contactPopup).fadeIn();
    }

    if($('.forgot-error').length) {
        $(forgotPopup).fadeIn();
    }

    if($('.booking-error').length) {
        $(bookingErrorPopup).fadeIn();
    }

    verifyPopupCloseBtn.addEventListener('click', function() {
        $(jsLogout).click();
    });

    if (messagePopupCloseBtn) {
        messagePopupCloseBtn.addEventListener('click', function() {
            $(messageSentPopup).fadeOut();
        });
    }


    if (bookingPopupCloseBtn) {
        bookingPopupCloseBtn.addEventListener('click', function() {
            $(bookingErrorPopup).fadeOut();
        });
    }


        checkEmailResetCloseBtn.addEventListener('click', function() {
            let redirect = $(this).val();
            window.location.href = redirect;
        });

    function imageSizeLimitter(input) {
        if (input.files[0].size > fileMaxSize) {
            alert("The image should be no more than " + fileMaxSize / 1024 + "Kb");
            input.value = '';
        }
    }

    fileInput.addEventListener('change', function() {
        imageSizeLimitter(this);
    });

})(jQuery);
