;(function($) {
    const tabs = $('.tabs__wrapper');
    const toggleBtn = $('.tabs-top-btn button');

    function changeTabs(parent, headerItems, bodyItems) {
        $(headerItems).on('click', function() {
            const current = $(this),
                indexItem = current.index();

            if( current.hasClass('tabs__item--active') ) return false;

            let mySwiper = undefined;
            headerItems.removeClass('tabs__item--active');
            headerItems.eq(indexItem).addClass('tabs__item--active');
            bodyItems.addClass('tabs__cont-item--hide');
            bodyItems.eq(indexItem).removeClass('tabs__cont-item--hide');
            if( $(bodyItems.eq(indexItem)[0]).find('.swiper-container')[0] ) {
                var currentSlider = $(bodyItems.eq(indexItem)[0]).find('.swiper-container')[0].getAttribute('id');
                mySwiper = new Swiper('#'+ currentSlider, {
                    init: false,
                    spaceBetween: 50,
                    speed: 500,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                });
                mySwiper.init();
            } else {
                if( mySwiper !== undefined ) {
                    mySwiper.destroy();
                }
                mySwiper = undefined;
            }
        })
    }

    function toggleInfo() {
        $(toggleBtn).on('click', function(){
            const $this = $(this);

            $this.toggleClass('toggle');
            $this.parents('.tabs-top--main').next().slideToggle();
        })
    }

    function initTabs() {
        tabs.each(function(indx, el) {
            const currentTab = $(el),
                headerItems = currentTab.find('.tabs__item'),
                bodyItems = currentTab.find('.tabs__cont-item');

            // headerItems.first().addClass('tabs__item--active');
            // bodyItems.not(':first-child').addClass('tabs__cont-item--hide');
            bodyItems.addClass('tabs__cont-item--hide');
            changeTabs(currentTab, headerItems, bodyItems);
            headerItems.first().click();
        });
    }

    if(tabs.length !== 0) {
        initTabs();
        toggleInfo();
    }
})(jQuery);