;(function($){
    /* Sticky */
    const sidebar = document.getElementById('sidebar');

    if(!sidebar) return false;

    let wrapper = document.querySelector('.search-container'),
        wrapperTop = $(wrapper).offset().top;

    function sticky() {
        let scrollTop = $(window).scrollTop();

        if( $(window).outerWidth() < 960 ) return false;

        if( scrollTop > 122 ) {
            let position = scrollTop - wrapperTop;

            sidebar.classList.add('sidebar--sticky');
            sidebar.style.top = position + 120 + 'px';
        } else {
            sidebar.classList.remove('sidebar--sticky');
            sidebar.removeAttribute('style');
        }
    }

    $(window).scroll(function() {
        sticky();
    });

    $(window).on('resize', function() {
        if($(window).innerWidth() < 861) {
            if(sidebar.classList.contains('sidebar--sticky')) {
                sidebar.classList.remove('sidebar--sticky');
                sidebar.removeAttribute('style');
            }
        }
    });
})(jQuery);
