;(function($){
    const search = $('#search'),
        wrapper = $('.main-form__line'),
        searchButton = $('#search-button');

    search.on('focus', function(){
        if( window.innerWidth > 760 ) {
            let wrapperWidth = wrapper.innerWidth();
            search.addClass('in-focus');
            search.css({'width': wrapperWidth + 'px'});
        }
    });
    // search.on('blur', function() {
    //     search.removeClass('in-focus');
    //     search.removeAttr('style');
    // });

    $('body').on('click', '.search-form-main', function (e) {
        e.stopPropagation();
    });

    $('body').on('click', function () {
        $('.search-form-main').find('input[name="main-search"]').removeClass('in-focus').removeAttr('style');
    });

    $(window).resize(function() {
        let wrapperWidth = wrapper.innerWidth();
        if(search.hasClass('in-focus')) {
            search.css({'width': wrapperWidth + 'px'});
        }
    });

    searchButton.on('click', function(e) {
        removeEmptyFormFields($(e.target).closest("form"));
    });

    function removeEmptyFormFields(form) {
        removeEmptySelects(form.find('select'));
        removeEmptyInputs(form.find('input'));
        handleSearchParamsPriority(form.find('input'), form.find('select'));
    }

    function removeEmptySelects(collection) {
        collection.each(function(select) {
            $(this).prop("disabled", $(this).val() == "");
        });
    }

    function removeEmptyInputs(collection) {
        collection.each(function(input) {
            $(this).prop("disabled", $(this).val() == "");
        });
    }

    /* remove selects filters if input search field is not empty */
    function handleSearchParamsPriority(input, selects) {
        if (input.first().val()) {
            selects.each(function() {
                $(this).prop("disabled", true);
            });
        }
    }
})(jQuery);
