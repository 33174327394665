;(function ($) {

    $('.clear-select').on('click', function(){
        var selectId =  $(this).siblings('.select').find('select').attr('id');
        switch (selectId) {
            case 'country':
                clearSelectById(selectId);
                clearSelectById('city', true);
                break;
            case 'category':
                clearSelectById(selectId);
                clearSelectById('speciality', true);
                clearSelectById('type', true);
                break;
            case 'city':
                clearSelectById(selectId);
                break;
            case 'speciality':
                clearSelectById(selectId);
                clearSelectById('type', true);
                break;
            case 'type' :
                clearSelectById(selectId);
                break;
            default:
        }
    });
    $('.main-form select, .search-form select').on('change', function(){
        var _this = $(this);
        if($(this).val() != "") {
            _this.parent().siblings('.clear-select').show();
            _this.parent().addClass('hide-dropdown');
        } else {
            _this.parent().siblings('.clear-select').hide();
            _this.parent().removeClass('hide-dropdown');
        }
    });

    function clearSelectById(selectId, clearOptions = false) {
        switch (selectId) {
            case 'country':
            case 'category':
                $("#" + selectId).val('');
                $("#" + selectId).siblings('.select-styled').html($("#" + selectId + ' option:first-child').text());
                $("#" + selectId).find('option:first-child').attr('selected', 'true').change();
                break;
            case 'city':
            case 'speciality':
            case 'type' :
                if(clearOptions == true) {
                    $("#" + selectId).siblings('.select-options').find('li:not(:first-child)').remove();
                    $("#" + selectId).find('option:not(:first-child)').remove();

                    $("#" + selectId).parent().siblings('.clear-select').hide();
                    $("#" + selectId).parent().removeClass('hide-dropdown');
                }
                $("#" + selectId).val('');
                $("#" + selectId).find('option:first-child').attr('selected', 'true').change();;
                $("#" + selectId).siblings('.select-styled').html($("#" + selectId +' option:first-child').text());
                break;
            default:
        }
        $("#" + selectId).trigger('change');
    }

    const LANG = $('html').attr('lang'),
        activeSearch = {
            selects: {
                country: {
                    paramName: 'country',
                    domElement: $('#country'),
                    dependentSelect: null,
                    dependOn: null,
                    loadedList: true,
                    apiUrl: null,
                },
                city: {
                    paramName: 'city',
                    domElement: $('#city'),
                    dependentSelect: null,
                    dependOn: null,
                    loadedList: false,
                    apiUrl: '/api/cities/'
                },
                category: {
                    paramName: 'category',
                    domElement: $('#category'),
                    dependentSelect: null,
                    dependOn: null,
                    loadedList: true,
                    apiUrl: null
                },
                speciality: {
                    paramName: 'speciality',
                    domElement: $('#speciality'),
                    dependentSelect: null,
                    dependOn: null,
                    loadedList: false,
                    apiUrl: '/api/company-specialities/'
                },
                type: {
                    paramName: 'type',
                    domElement: $('#type'),
                    dependentSelect: null,
                    dependOn: null,
                    loadedList: false,
                    apiUrl: '/api/types/'
                },
            },
            inputs: [
                {
                    paramName: 'main-search',
                    domElement: $('#search')
                },
            ],
        };



    activeSearch.selects.country.dependentSelect = activeSearch.selects.city;
    activeSearch.selects.city.dependOn = activeSearch.selects.country;

    activeSearch.selects.category.dependentSelect = activeSearch.selects.speciality;
    activeSearch.selects.speciality.dependOn = activeSearch.selects.category;

    activeSearch.selects.speciality.dependentSelect = activeSearch.selects.type;
    activeSearch.selects.type.dependOn = activeSearch.selects.speciality;

    $('select').each(function () {
        var $this = $(this),
            numberOfOptions = $(this).children('option').length,
            lineWrapper = $('.main-form__line');

        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());

        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            let text = $this.children('option').eq(i).text();
            let rel = $this.children('option').eq(i).val();
            if (i === 0) {
                $('<li />', {
                    text: 'stub',
                    rel: rel
                }).appendTo($list);
            } else {
                $('<li />', {
                    text: text,
                    rel: rel
                }).appendTo($list);
            }
        }

        var $listItems = $list.children('li');

        function addStyle() {
            lineWrapper.addClass('main-form__line--ext');
        }

        function delStyle() {
            lineWrapper.removeClass('main-form__line--ext');
        }

        $styledSelect.click(function (e) {
            var indx = $(e.target).parents('.main-form__piece').index();
            var wrapper = $('.main-form__line');
            e.stopPropagation();
            if (indx === 1) {
                if (!wrapper.hasClass('main-form__line--ext')) {
                    addStyle();
                } else {
                    delStyle();
                }
            } else {
                delStyle();
            }
            $('div.select-styled.active').not(this).each(function () {
                $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
        });

        $listItems.click(function (e) {

            delStyle();
            $this.find('option:first-child').attr('selected', 'true');
            $this.children().attr('selected', false).prop('selected', false);
            e.stopPropagation();
            var currentIndx = $(this).index();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.children().eq(currentIndx).attr('selected', true).prop('selected', true).change();
            $this.val($(this).attr('rel'));
            $list.hide();

            $this.parent().addClass('hide-dropdown');
            $this.parent().siblings('.clear-select').show();
        });

        $(document).click(function () {
            delStyle();
            $styledSelect.removeClass('active');
            $list.hide();
        });
    });

    $('select').change(function (event) {
        const changedSelectId = $(event.currentTarget).prop('id');
        const changedSelectElement = document.getElementById(changedSelectId);
        let selectValue = "";
        if(changedSelectElement) {
            selectValue = changedSelectElement.querySelector('[selected]').value;
        }

        if (selectValue) {
            switch (changedSelectId) {
                case 'country' :
                    getDependsOptions(
                        {
                            'country': selectValue,
                            'with-companies': true,
                            'lang': LANG,
                            'identifier': 'name'
                        },
                        '/api/cities/',
                        $('#city')
                    );
                    break;
                case 'category' :
                    getDependsOptions(
                        {
                            'category': selectValue,
                            'with-companies': 1,
                            'lang': LANG,
                            'identifier': 'name',
                        },
                        '/api/company-specialities/',
                        $('#speciality')
                    );
                    break;
                case 'speciality' :
                    getDependsOptions(
                        {
                            'speciality': selectValue,
                            'with-companies': 1,
                            'lang': LANG,
                            'identifier': 'name'
                        },
                        '/api/types/',
                        $('#type')
                    );
                    break;
            }
        }

    })

    function doAjax(url, params) {
        return new Promise(async function(resolve, reject) {
            try {
                var result = await $.ajax({
                    url: url + '?' + params,
                    cache: false,
                    type: 'GET',
                    headers: {
                        "Content-Type": "json",
                    }
                });
            } catch (error) {
                reject();
            }
            resolve(result);
        });
    }

    async function getDependsOptions(query, url, dependSelect) {
        var params = $.param(query);
        await doAjax(url, params).then(
            result => handleAjaxResult(result, dependSelect),
            error => handleAjaxError(dependSelect)
        );
    }

    function handleAjaxResult(data, dependSelect) {
        cleanSelect(dependSelect);
        handleData(data, dependSelect);

        var virtualSelect = dependSelect.parent();
        var numberOfOptions = dependSelect.children('option').length;

        var styledSelect = virtualSelect.find('.select-styled');

        var list = virtualSelect.find('.select-options');
        list.empty();


        for (var i = 0; i < numberOfOptions; i++) {
            let text = dependSelect.children('option').eq(i).text();
            let rel = dependSelect.children('option').eq(i).val();
            if (i === 0) {
                $('<li />', {
                    text: 'stub',
                    rel: rel
                }).appendTo(list);
            } else {
                $('<li />', {
                    text: text,
                    rel: rel
                }).appendTo(list);
            }
        }

        list.on('click', function (event) {
            var currentIndex = $(event.target).index();
            var select = $(this).parent().find('select');
            select.children().removeAttr('selected');
            select.children().eq(currentIndex).attr('selected', true).change();
            list.prev().text($(event.target).html());
            event.stopPropagation();
            $(this).hide();

            select.parent().siblings('.clear-select').show();
            select.parent().addClass('hide-dropdown');

        });


        styledSelect.text(dependSelect.children().eq(0).text());
        list.insertAfter(styledSelect);
    }

    function handleAjaxError(dependSelect) {
        cleanSelect(dependSelect);
    }

    function insertNewOption(select, key, val) {
        var newOption = $('<option value="' + key + '">' + val + '</option>')
        select.append(newOption);
    }

    function cleanSelect(select) {
        select.children().not(':first').remove().end();
    }

    function handleData(data, dependSelect) {
        if ($.isArray(data)) {
            data.forEach(function (item) {
                insertNewOption(dependSelect, item.id, item.name);
            });
        } else {
            $.each(data, function (key, value) {
                insertNewOption(dependSelect, key, value);
            });
        }
    }

    function handleActiveSearch(activeSearch, queryParams) {
        handleActiveSelects(activeSearch.selects, queryParams);
        handleActiveInputs(activeSearch.inputs, queryParams);
    }

    async function handleActiveSelects(selects, queryParams) {
        for (const select in selects) {
            let isParamExists = queryParams.has(selects[select].paramName);
            let paramValue = queryParams.get(selects[select].paramName);
            if (isParamExists && paramValue) {
                await fillActiveSearchSelect(selects[select], paramValue);
            }
        }
    }

    function handleActiveInputs(inputs, queryParams) {
        $.each(inputs, function(index, input) {
            if (queryParams.has(input.paramName) && queryParams.get(input.paramName)) {
                input.domElement.val(queryParams.get(input.paramName));
            }
        });
    }

    async function fillActiveSearchSelect(select, activeValue) {
        let options = select.domElement.find('option');
        options.each(function() {
            let option = $(this);
            if (option.val().toLowerCase() === activeValue.toLowerCase()) {
                option.attr("selected", true);
                select.domElement.next().text(option.text());
            }
        });
        if (select.dependentSelect && select.domElement.val()) {
            let apiParamsObject = {
                'with-companies': 1,
                'lang': LANG,
                'identifier': 'name',
            };
            apiParamsObject[select.paramName] = select.domElement.val();
            await getDependsOptions(
                apiParamsObject,
                select.dependentSelect.apiUrl,
                select.dependentSelect.domElement
            );
        }
    }

    handleActiveSearch(activeSearch, new URLSearchParams(window.location.search));

    $('#clear-button').on('click', function(){
        $('#country').val('');
        $('#country').siblings('.select-styled').html($('#country option:first-child').text());

        $('#city option:not(:first-child)').remove();
        $('#city').val('');
        $('#city').siblings('.select-styled').html($('#city option:first-child').text());
        $('#city').siblings('.select-options').find('li:not(:first-child)').remove();

        $('#category').val('');
        $('#category').siblings('.select-styled').html($('#category option:first-child').text());

        $('#speciality option:not(:first-child)').remove();
        $('#speciality').val('');
        $('#speciality').siblings('.select-styled').html($('#speciality option:first-child').text());
        $('#speciality').siblings('.select-options').find('li:not(:first-child)').remove();

        $('#type option:not(:first-child)').remove();
        $('#type').val('');
        $('#type').siblings('.select-styled').html($('#type option:first-child').text());
        $('#type').siblings('.select-options').find('li:not(:first-child)').remove();
    });


})(jQuery);
